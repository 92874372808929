import wrapWithProvider from './src/components/wrap-with-provider';
import './src/styles/global.css';
export const wrapRootElement = wrapWithProvider;

export const onRouteUpdate = () => {
  const scrollPosition = sessionStorage.getItem('scrollPosition');
  if (scrollPosition) {
    window.scrollTo(0, parseInt(scrollPosition, 10));
    sessionStorage.removeItem('scrollPosition');
  }
};
